import { createGlobalStyle } from "styled-components";

export const FontStyles = createGlobalStyle`
    @font-face {
        font-family: 'unica77LL-regular';
        src: url('/fonts/Unica77LL-Regular.otf') format('otf');
        font-weight: normal;
        font-style: normal;
    }
`;
